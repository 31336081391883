import React from "react";

function GuruAward() {
  return (
    <div
      id="cap_gold"
      data-length="29"
      className="cap_gold_black"
      onClick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.cap_gold_name').href);return 0;}"
    >
      <div className="cap_gold_nom">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="176px"
          height="176px"
          viewBox="0 0 176 176"
        >
          <defs>
            <path
              id="cap_gold_nom-arc1"
              d="M 30 88 a 58 58 0 1 1 116 0"
            ></path>
          </defs>
          <text className="cap_gold_nom_txt" fill="#000" textAnchor="middle">
            <textPath startOffset="50%" xlinkHref="#cap_gold_nom-arc1">
              ★ Recommended ★
            </textPath>
          </text>
        </svg>
      </div>
      <p className="cap_gold_year">2024</p>
      <a
        target="_blank"
        href="https://restaurantguru.com/Smile-Cafe-Saxmundham"
        className="cap_gold_name str1"
        rel="noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="700px"
          height="700px"
          viewBox="0 0 700 700"
        >
          <defs>
            <path
              id="cap_gold_name-arc"
              d="M 30 350 a 320 320 0 1 1 640 0"
            ></path>
          </defs>
          <text
            className="cap_gold_name_txt f20"
            fill="#fff"
            textAnchor="middle"
          >
            <textPath startOffset="50%" xlinkHref="#cap_gold_name-arc">
              Smile Cafe
            </textPath>
          </text>
        </svg>
      </a>
      <a
        className="cap_gold_home"
        style={{ fontSize: 0 }}
        href="https://restaurantguru.com"
        target="_blank"
        rel="noreferrer"
      >
        Restaurant Guru
      </a>
    </div>
  );
}

export default GuruAward;
