import React from "react";
import {
  articlesPicsThroughout,
  articles,
  articlesJan2024,
} from "../content/article-content";

export function LatestNews() {
  return (
    <>
      <h1>Latest news</h1>
      <h4>
        For the very latest information and news, please check our new Facebook
        page,{" "}
        <a href="https://www.facebook.com/groups/422101183471242">
          https://www.facebook.com/groups/422101183471242
        </a>
        <br />
        <br />
        If you live in and around Sax, why not join our private group on
        Facebook - simply click on the link above and request membership.👍😎
      </h4>
      <p class="latestNewsIntro">
        First and foremost the SMILE Cafe is a local pop-up community cafe. We
        aim to be fully inclusive to encourage and support everyone, including
        those with various physical and mental disabilities and their family
        carers, where applicable. The Cafe is housed in a very modern building,
        with ample on-site parking with wheelchair-friendly access and a
        disability toilet / baby changing facilities. In addition, we have
        purchased a special adaptable baby / toddler high chair and special
        disability cutlery for those with frail fingers / hands.The menu
        includes a number of hot and cold snacks and meals, at very affordable
        prices - for those seeking inexpensive meals, within a warm, welcoming
        and unhurried social cafe. The whole social enterprise is managed and
        run by volunteers, including those with mild learning disabilities and
        mental health issues. The cafe is self-sustaining, but we do seek minor
        grants to provide occasional special free events / free live
        entertainment - for all ages.
      </p>
      <p class="latestNewsIntro">
        All 2023 special free events and live entertainment were kindly
        sponsored by Saxmundham Town Council
      </p>
      {articlesJan2024.map((article, index) => (
        <div className="articleContainer">
          <article className={index === 0 ? "article1" : "article"}>
            <h3>{article.title}</h3>
            {article.content.map((item) => (
              <p>{item}</p>
            ))}
            <div className="picContainer">
              {article.pictures.map(({ key, src }, index) => (
                <img
                  className={"latestNewsPic" + article.pictures.length}
                  key={key}
                  src={src}
                  alt={`${article.title} pic ${index}`}
                />
              ))}
              {article.links.map(({ key, email, label }) => (
                <p>
                  <text>
                    {label + "\n"}
                    <a className="emailAdd" key={key} href={email}>
                      {email}
                    </a>
                  </text>
                </p>
              ))}
            </div>
            <div className="articleDivider"></div>
          </article>
        </div>
      ))}
      {articlesPicsThroughout.map((article, index) => (
        <div className="articleContainer">
          <article className="article">
            <h3>{article.title}</h3>
            {article.content.map((item, index) => (
              <>
                <div className="picContainer">
                  <img
                    className="latestNewsPic1"
                    key={index}
                    src={item.pic.src}
                    alt={`${article.title} pic ${index}`}
                  />
                </div>
                <p>{item.text}</p>
              </>
            ))}
          </article>
        </div>
      ))}
      {articles.map((article, index) => (
        <div className="articleContainer">
          <article className="article">
            <h3>{article.title}</h3>
            {article.content.map((item) => (
              <p>{item}</p>
            ))}
            <div className="picContainer">
              {article.pictures.map(({ key, src }, index) => (
                <img
                  className={"latestNewsPic" + article.pictures.length}
                  key={key}
                  src={src}
                  alt={`${article.title} pic ${index}`}
                />
              ))}
              {article.links.map(({ key, email, label }) => (
                <p>
                  <text>
                    {label + "\n"}
                    <a className="emailAdd" key={key} href={email}>
                      {email}
                    </a>
                  </text>
                </p>
              ))}
            </div>
            <div className="articleDivider"></div>
          </article>
        </div>
      ))}
    </>
  );
}

export default LatestNews;
