import Rotary from "../images/rotary.png";
import Certificates from "../images/2022.11.09 - certificates.jpg";

const AboutContent = [
  {
    title: "Back Story",
    content: [
      "The SMILE Cafe was set up on October 4th, 2012, by the, PASTEL Community Interest Company, with support from The Rotary Club of Saxmundham & District. It was sponsored by both organisations and became self-sustaining within the first year.",
      "The SMILE Cafe was managed by PASTEL for the first two and a half years, from October 2012 to April 2015, when the roles were reversed and the management was handed over to the Rotary Club, with PASTEL in support.",
      "Sadly in July 2022, both PASTEL CIC and the local Saxmundham Rotary club were formally dissolved - PASTEL CIC as a result of the forced 2-year Covid19 close down, and the local Rotary club because of declining and aging membership.",
      "However, some ex-volunteers - from both PASTEL and the local Rotary club - decided to carry on as the 'SMILE Community Cafe' - as an independent social enterprise in its own right.",
      "All the SMILE Community Cafe team are volunteers.",
      <span>
        And we would like to offer thanks to fellow volunteers:
        <ul>
          <li>Joe Hopkins for creating our new website.</li>
          <li>Adam Tricker for creating our new logo </li>
          <li>Stevie Price for the website photos</li>
          <li>
            and ongoing support from{" "}
            <img src={Rotary} alt="Rotary logo" style={{ height: "1em" }} />{" "}
            Rotary in East Anglia.
          </li>
        </ul>
      </span>,
      <figure className="picContainer">
        <img src={Certificates} alt="certificates" className="aboutPic" />
        <figcaption>
          l-r: Mike, Tom and Brian with training certificates, presented by
          County Councillor, Richard Smith
        </figcaption>
      </figure>,
      "And, Joint Volunteer Managers, Don Tricker (ex-PASTEL CIC Volunteer Director) & Graham Ward (Ex-Chairman, Saxmundham Rotary Club)",
    ],
  },
  {
    title: "Volunteers",
    content: [
      <strong>Help fellow residents - We need more volunteers to join the team!</strong>,
      <strong>We need extra hands for serving drinks and cakes / help clearing tables / supporting the cook...</strong>,
      <strong>Simple but extremely important!</strong>,
      <strong>Have you got 2 or 3 hours on a Wednesday, between 11am and 2pm?</strong>,
      <strong>Please pop in, on a Wednesday, see the set-up and talk to us - we don't bite😊 - to find out more and see if it's for you!🤔</strong>,
      "Whatever your age, or ability, get a sense of personal achievement and satisfaction by becoming a SMILE Cafe volunteer!",
      "Do you want to feel good about helping others, as well as meet new people and make new friends, add to your social life, learn new skills, create a useful addition to your CV and have fun? Then why not ask about becoming a SMILE Cafe volunteer, whether on the management or operations side, or both!",
      "We provide induction, and further training for those interested.",
      "If you're unemployed, volunteering with the SMILE Cafe could help you into work by building your skills and confidence. It could be the vital stepping stone you need to move into employment. As a volunteer with the SMILE Cafe you could even gain a national training accreditation to add to your CV In particular:",
      <strong>
        •• learn new skills •• practise the skills you have •• become more
        confident •• put some volunteering experience on your CV •• pick up
        good ideas from other people •• show employers you can keep regular
        hours and handle commitment •• meet people who can help you find paid
        work •• have things to talk about in a job interview,and •• get
        references.
      </strong>,
      "However, we 're always interested to talk to anyone with experience in project management, administrative work, finance, fundraising, PR & media or teaching/training as well as general SMILE Cafe support activities.",
      "We provide individual training to a dedicated team of volunteers. The volunteers particpate in catering and related accredited and informal training which is organised according to their aspirations, personal needs and abilities. The Cafe Training Project provides a cafe facility for all the local community, including those with special needs.",
      "Please use the contact information below to enquire about joining the volunteer team.",
    ],
  },
];

export default AboutContent;

// Old Bits

// {
//   title: "Rotary Club of Saxmundham & District",
//   content: [
//     "Rotary International (also known as the Rotary Club) is an international service club whose stated purpose is to bring together business and professional leaders in order to provide humanitarian services, encourage high ethical standards in all vocations, and help build goodwill and peace in the world.",
//     "There are 34,282 clubs and over 1.2 million members worldwide.",
//     "The Rotary Club of Saxmundam & District covers the towns of Aldeburgh, Framlingham, Leiston, Saxmundham, together with the surrounding villages.",
//   ],
// },

// {
//   title: "Customers",
//   content: [
//     <strong>Meet and chat to others in a warm supportive setting</strong>,
//     "The SMILE cafe is all about reducing social isolation and is a welcoming place for all those seeking some friendly company. Those living alone, over 50 and / or retired are particularly welcome too.",
//     "The expectation is that SMILE Cafe visitors will make local friendships and support each other outside of the SMILE Café setting.      ",
//     "Among the variety of customer groups that the SMILE Cafe aims to support are those diagnosed with dementia. At present there are 9,000 people in Suffolk diagnosed with dementia and, by 2030, that figure is expected to double.",
//     "The SMILE Cafe will develop elements of a “memory cafe” and provide a place where people with dementia, and their carers, can visit to support each other, share information and enjoy reminiscence-based activities.",
//     "However, the SMILE Cafe is not suitable for people with dementia to attend alone if they have specific care needs such as problems with continence, mobility issues that require aid, high levels of anxiety, challenging behaviour or medical conditions that require monitoring",
//   ],
// },
// {
//   title: "Carers",
//   content: [
//     <>
//       <strong>The Unpaid Army.</strong>
//       <br />
//     </>,
//     "There are approximately 6.4million people in the UK providing care for ill or disabled loved ones.",
//     "It is estimated that unpaid Carers save the NHS and local authorities as much as £119 billion a year.",
//     "The Suffolk Joint Health and Wellbeing Strategy states “ Suffolk has 66,109 'family carers' and 3,414 of them are aged five to 24. Their health and wellbeing is ‘essential’.”",
//     "It is envisaged that some Carers having visited the SMILE Cafe will swap phone numbers and give each other a call from time to time, arrange to meet at the SMILE Cafe or just drop in to relax and unwind.",
//   ],
// },
// {
//   title: "Professionals",
//   content: [
//     <>
//       <strong>Meet clients in a relaxed and friendly setting</strong>
//       <br />
//     </>,
//     "The SMILE Cafe provides a place where information on other community activities can be on hand.",
//     "Relevant leaflets may be handedt in the SMILE cafe.",
//     "Professional advisors / counsellors are encouraged to meet clients / carers at the SMILE Cafe and book a private meeting room for individual counselling, if required.",
//   ],
// },
