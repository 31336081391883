import { Link } from "react-router-dom";
import Councillor from "../images/councillor.jpg";
import pic201503121 from "../images/20150312-1.jfif";
import pic201503122 from "../images/20150312-2.jfif";
import pic201503123 from "../images/20150312-3.jfif";
import pic20151004 from "../images/20151004.jpg";
import Carers from "../images/2022carers.jpg";
import Ukelele from "../images/2022uke-n-smile.jpg";
import Saxfest1 from "../images/2022saxfest1.jpg";
import Saxfest2 from "../images/2022saxfest2.jpg";
import Saxfest3 from "../images/2022saxfest3.jpg";
import Banner from "../images/2022.11.09 - banner.jpg";
import CounterVolunteers from "../images/2022.11.09 - counter volunteers.jpg";
import BBC from "../images/2022.11.09 - BBC.jpg";
import CustomerVolunteer from "../images/2022.11.09 - customer and volunteer.jpg";
import Certificates from "../images/2022.11.09 - certificates.jpg";
import Ukelele2 from "../images/2022.11.09 - Ukelele.jpg";
import Feeding from "../images/2022.11.09 - feeding.jpg";
import Longshot from "../images/2022.11.09 - long shot customers.jpg";
import Coronation from "../images/2024.01.09 Coronation.jpg";
import Christmas2023 from "../images/2024.01.09 Christmas Celebration.jpg";
import Oktoberfest2023 from "../images/2024.01.09 Oktoberfest.jpg";
import Summershow2023 from "../images/2024.01.09 Summer show.jpg";

// to add a new article, copy the below structure below the "Marked Point" and remove the "/*" and "*\"
/* {
   title: "",
  content: [
 ],
   pictures: [{ src: Councillor }],
   links: [],
},
*/

export const articlesJan2024 = [
  {
    title: "Christmas Celebration (December, 2023)",
    content: [
      "The last busy trading day of the year featured a Christmas celebration with non-alcoholic sparkling wine, free luxury mince pies or pigs in blankets, with a free tea or instant coffee (other coffees were available) - and live Christmas carols and songs from the ten-strong Uke-n-Smile ukulele band, providing a great atmosphere and lots of sing-along tunes! ",
      <text>
        Also on trial was special SMILE Christmas Turkey Bap, with slices of
        roast turkey, cranberry sauce, slices of back bacon, gem lettuce,
        sliced vine tomato and mayonnaise (optional), This was such a success
        that a similar SMILE Club bap, with slices of roast chicken, will
        feature on the 2024 menu:
        <a href="https://www.smile-cafe.org.uk/menu">Smile cafe menu</a>
      </text>,
    ],
    pictures: [{ src: Christmas2023 }],
    links: [],
  },
  {
    title: "OktoberFest (October, 2023)",
    content: [
      "Today's special Oktoberfest menu of German food and non-alcoholic German beer was a popular, but muted, success - no raucous singing, no stamping of feet or wild dancing - but the food and drink slowly all disappeared... 👍",
      "One customer turned up in his gardening clothes - trying to pass off his outfit as traditional German apparel! 🤠",
      "County Councillor, Richard Smith dropped in to sample the special offering and wish us well.",
    ],
    pictures: [{ src: Oktoberfest2023 }],
    links: [],
  },
  {
    title: "Summer Show (August, 2023)",
    content: [
      "Over 80 people - babies, toddlers, youngsters, their parents and grandparents - streamed in and out of the SMILE Community Cafe all day - to enjoy the free entertainment! There were morning and afternoon Punch and Judy shows and, in support, there was face-painting (sponsored by Christies Care), as well as live music from a female singer and her guitar.",
      "There was a wonderful laid-back atmosphere - despite all the shouting, screaming and laughing during the very interactive Punch & Judy show - and that was just from the adults reliving their childhoods!😅",
    ],
    pictures: [{ src: Summershow2023 }],
    links: [],
  },
  {
    title: "King Charles III Coronation Celebration (April, 2023)",
    content: [
      "In a full house, we toasted our new king, with glasses of non-alcohol sparkling wine. To add to the atmosphere, we arranged for some live music and an amazing professional magician - moving amongst the tables performing some truly amazing close up magic!",
    ],
    pictures: [{ src: Coronation }],
    links: [],
  },
];

export const articlesPicsThroughout = [
  {
    title: "10th Anniversary Celebration",
    content: [
      {
        text: "On Wednesday Nov.9th - to celebrate 10 years of operations - the SMILE Community Cafe threw open its doors to offer customers free coffee / cake / tea / biscuits and live music, provided by the Uke-n-Smile group.",
        pic: { src: Banner },
      },
      {
        text: (
          <blockquote>
            "The day was a big success. We were swept off our feet by the
            response, which also included a visit by BBC Radio Suffolk to
            conduct two live broadcast interviews with the volunteer staff!"
            <cite> - Don Tricker, Volunteer Joint Manager.</cite>
          </blockquote>
        ),
        pic: { src: CounterVolunteers },
      },
      {
        text: "Founding volunteer Don welcomed the longest serving customer, Dominic - both of whom have supported the cafe throughout the full 10 years, since its inception.",
        pic: { src: BBC },
      },
      {
        text: "Mid-morning, there was a break in proceedings while another regular customer from the early days, County Councillor, Richard Smith, publicly presented certificates to three volunteers, all of whom had passed their professional hygiene training exam. The cafe has been inspected four times in its life and, each time, passed with a full five stars.",
        pic: { src: CustomerVolunteer },
      },
      {
        text: "The SMILE Community Cafe aims to provide a welcoming and friendly setting, helping customers to make new friends and help make the community more inclusive. Along with providing the papers and games, from time to time, the cafe also provides free live music. ",
        pic: { src: Certificates },
      },
      {
        text: (
          <blockquote>
            "We attract a great cross-section of the community, from families
            with toddlers to senior citizens. A great number of our customers
            are regulars, including friends and neighbours who meet up for
            brunch together - to enjoy our very popular all-day breakfast. And,
            because we're all volunteers we are able to offer quality food at
            very reasonable prices. We're now looking forward to another
            productive ten years of putting a smile on customers' faces!"
            <cite> - Graham Ward, Volunteer Joint Manager</cite>
          </blockquote>
        ),
        pic: { src: Ukelele2 },
      },
      {
        text: "The SMILE Community Cafe has seating for up to 50 people, socially distanced.",
        pic: { src: Feeding },
      },
      {
        text: "",
        pic: { src: Longshot },
      },
    ],
  },
];

export const articles = [
  // Marked point
  {
    title: "The SMILE Community Cafe joins the 2022 Sax Community Fest.",
    content: [
      <text>
        The Saxmundham Music & Arts Community Interest Company ('SMART') -
        promoters of the Sax Music Fest - decided to relaunch the event in
        July, 2022 (after 2 years Covid 19 absence) and return with a broader
        offering, to include community services and rename the festival -
        <a href="https://www.saxmusicfest.co.uk/">
          The Saxmundham Community Festival
        </a>
        . And, it still remains a FREE festival!
      </text>,
      "The SMILE Cafe worked with 'SMART' to secure funding for a dedicated marquee and associated facilities - in order to manage a refreshment station, in particlar for the frail and elderly and those with disabilities. The call was to provide an inclusive and friemdly oasis in order to help improve social inclusion. Customers could come in, sit down relax and have a chat.",
      "So during the days of Friday July 29th and Saturday July 30th, the SMILE Community Cafe set up shop - setting up the pop-up cafe in an outside setting fot the first time!",
      "And, what a success it was! Two record days of takings demonstrated its popularity and several new friends were made in the process.",
      "We would like to extend our  thanks to the SMART team of volunteers for helping with our setting up and trouble shooting some initial teething problems - with a special recognition for Terry Barrow (the Festival Main Man).",
    ],
    pictures: [{ src: Saxfest1 }, { src: Saxfest2 }, { src: Saxfest3 }],
    links: [],
  },
  {
    title: "The SMILE Cafe relaunches - following the Covid-19 Pandemic",
    content: [
      <text>
        <strong>The SMILE Cafe </strong>
        closed temporarily with the first UK lockdown in March 2020- and
        couldn't re-open untl January, 2022!
      </text>,
      "First in line - as the SMILE Café relaunched at the new Fromus Centre – was Saxmundham Town Councillor Roger Hedley Lewis, who had brought with him a small breakfast party to test out the new catering service in town.",
      "No sooner had the tables been set out, laid and sanitised, than the orders were in – a mix of tea, coffee and hot chocolate, porridge (extra thick), toast with jam, beans on toast and a full English breakfast",
      "With a brand new commercial kitchen, new equipment and eight halogen plates, the head cook and kitchen staff were immediately under pressure to get ‘firing on all eight’.",
      "The first Wednesday, with no promotion, was meant to be a very soft launch to trial the system but once service was under way, a steady stream of customers meant the kitchen service didn’t get a break until 1.30pm!",
      <blockquote>
        "I was very impressed with the new surroundings and the socially
        distanced tables. My all day breakfast was not only delicious but great
        value for money. I noted that daily newspapers were provided and, all
        in all, there was a relaxed and friendly ambiance. I will be back."{" "}
        <cite> - Cllr. Roger Hedley Lewis, Saxmundham Town Council</cite>
      </blockquote>,
    ],
    pictures: [{ src: Councillor }],
    links: [],
  },
  {
    title: "October 4th: A Resounding Success!",
    content: [
      "On the first day of opening, over 100 customers streamed into the SMILE Cafe in the first 3 hours!",
      <text>
        After all the hard work put in by a small, but determined, band of
        wonderful volunteers the venture was declared a great success by all
        attending, please <Link to="/customer_feedback">click here</Link> to
        see what customers had to say in their own words.
      </text>,
      "We would like to give a special vote of thanks to Sharon and her team from Coastal Caterers (www.coastalcaterers.co.uk) who provided a range of complimentary canapes and their own staff to pass them round and, in so doing, took some of the strain of accomodating so many customers.",
      "The challenge now is to reach out to all those people, in this rural part of East Suffolk, who are experiencing varying degrees of social isolation and let them know that there is a friendly and welcoming oasis in Saxmundham - a place to relax, unwind, meet and chat with others in similar circumstances, and also have the opportunity to meet professionals to gain valuable information, advice and guidance",
      "Please help to spread the word - we're here to help!",
    ],
    pictures: [{ src: pic20151004 }],
    links: [],
  },
  {
    title: "The Big Launch!",
    content: [
      "The SMILE cafe will be launched at the Fromus Centre, Saxmundam at 11am on Thursday October 4th, 2012",
      <strong>
        Preparations to Launch the pop-up SMILE Cafe at the Fromus Centre....
      </strong>,
      "PASTEL and the Rotary Club of Saxmundham & District have a final round of meetings in September, to launch the SMILE cafe - a cafe aimed at supporting adults with additional needs and Carers within rural East Suffolk.The SMILE Cafe will be a pop-up cafe operating at the Fromus Centre in Saxmundham, every Thursday 10am - 2pm and will be staffed by community volunteers",
      "The SMILE Cafe will managed by PASTEL. The Rotary Club of Saxmundham & District will provide seed-corn funding, equipment, tableware and back-up expertise.",
      "PASTEL is currently recruiting and training volunteers to staff and help run the cafe.",
      "The project is financed by both PASTEL and Rotary, with additional funding received from the Saxmundham Waitrose superstore and EDF Energy",
      "However, we wish to give particular thanks for the recognition of the extent of the SMILE Cafe customer coverage, and associated funding support, from our 4 local county councillors. They have each made contributions from their personal Locality Budgets:",
      <ol>
        <li>1. Cllr. Colin Hart (Framlingham)</li>
        <li>2. Cllr. Rae Leighton (Blything)</li>
        <li>3. Cllr. Andrew Reid (Wilford)</li>
        <li>4. Cllr. Richard Smith, MVO (Aldeburgh and Leiston)</li>
      </ol>,
    ],

    pictures: [],
    links: [],
  },
];

/* old pic imports
import Hygiene from "../images/hygiene.jpg";
import Breakfast000 from "../images/breakfast000.jfif";
import pic20161006 from "../images/20161006.jpg";
import pic201602181 from "../images/20160218-1.jpg";
import pic201602182 from "../images/20160218-2.jpg";
import pic201602183 from "../images/20160218-3.jpg";
import pic201602184 from "../images/20160218-4.jpg";
import pic201602185 from "../images/20160218-5.jfif";
import pic201602186 from "../images/20160218-6.jfif";*/

/* Old content
{
  title:
    "The SMILE Café presents an enjoyable diversion from ‘Brexit’ on Dec.12th",
  content: [
    `On Thursday December 12th, 2019, Election Day, the SMILE Café want to take you back to the good old days with a 40’s & 50’s singalong session - with live music and singing provided by the local female duo ‘Sing As We Go’. The pop-up SMILE Café runs from 10am to 2pm and the entertainment will start at 11.30am - so get in early to get a seat! This leaves you plenty of time to vote, either before or after and helps to brighten up the day.`,
    `Grab a coffee and cake, or a light lunch, while you join in with the nostalgic sing-a-long with local duo Justine and Michelle and, if you’re in the mood , feel free to come along dressed in 40’s or 50’s fashion! ‘Sing As We Go’ have been providing popular entertainment for several years at local care homes, dementia groups, anniversary parties and themed 40’s & 50’s nights in restaurants.`,
    `The SMILE Café is a pop-up community café run by the local Rotary Club for absolutely everyone in the Saxmundham area and is open every Thursday from 10am to 2pm for breakfasts, snacks and light lunches. The café is based in the Main Hall and kitchen of the Fromus Centre, Seaman Avenue, Saxmundham, opposite the Fire & Police station. The SMILE Café can seat up 50 customers, has disabled access and toilets and offers plenty of free car parking.`,
    <blockquote>
      "After 7 successful years, it’s very rewarding to see the local Rotary
      Club / PASTEL partnership continues to work so well, providing a
      popular community service for all local residents. On Dec.12th, we hope
      to provide a little lively and enjoyable diversion from the more
      heavyweight issues of the day"
      <cite>
        {" "}
        - Graham Ward, Treasurer, Saxmundham & District Rotary Club.
      </cite>
    </blockquote>,
  ],
  pictures: [],
  links: [],
},
{
  title:
    "October 26th, 2017 : The SMILE Cafe celebrates its 5th Anniversary since opening!",
  content: [
    `On that day, while stocks last, the SMILE Cafe will be offering free teas / coffees and cakes and pastries!`,
    `There will also be live music provide by The Green Elastic Band, with melodeon, flute and guitar.`,
    <text>
      <em>
        “It’s extremely gratifying to know that my community grant of 5 years
        ago has paid off with such a handsome dividend for the residents of
        Saxmundham. Having eaten myself there on a couple of occasions I can
        happily testify to the quality of the food and great value-for money.
        And, long may it continue!”{" "}
      </em>
      County Councillor Richard Smith
    </text>,
    `The popular SMILE Cafe is a community cafe, manned by volunteers, and is self-sustaining.It provides everything from it's famous all-day breakfast for £3, porridge with honey for £1, bacon rolls and hot dogs for £1.50 and light lunches eg baked potatoes with various toppings, as well as various 'toasties' and panninis.`,
    `Everyone is welcome!`,
  ],
  pictures: [],
  links: [],
},
{
  title: "July 29th, 2017 : The SMILE Cafe is awarded the maximum 5 stars",
  content: [
    `Folowing an inspection by the Food & Safety Team from the Suffolk Coastal District Council, the SMILE Cafe has again been rated 'very good' and awarded the maximum 5 stars..`,
    <img src={Hygiene} alt="hygiene"></img>,
    `The law requires that food businesses and other premises where food is handled or stored are registered with or formally approved by the local authority.`,
    <text>
      <em>
        “It’s extremely gratifying to know that my community grant of 5 years
        ago has paid off with such a handsome dividend for the residents of
        Saxmundham. Having eaten myself there on a couple of occasions I can
        happily testify to the quality of the food and great value-for money.
        And, long may it continue!”{" "}
      </em>
      County Councillor Richard Smith
    </text>,
    `The food hygiene rating scheme is run by the Food Standards Agency in partnership with local authorities in England, Wales and Northern Ireland.`,
    `The inspectors will check if the business produces food that is safe to eat. To do this, they will look at: the premises, the kinds of food made or prepared, how the business works the the food safety management system.`,
    <text>
      For more information,{" "}
      <a href="https://www.food.gov.uk/business-industry/caterers/sfbb/sfbbcaterers">
        click here,{" "}
      </a>
      or contact the environmental health service at Suffolk Coastal District
      Council for advice.
    </text>,
  ],
  pictures: [],
  links: [],
},
{
  title:
    "April 6th, 2017 : The SMILE Cafe launches a new All-Day Breakfast... for only £3!s",
  content: [],
  pictures: [{ src: Breakfast000 }],
  links: [],
},
{
  title:
    "October 6th, 2016 : The SMILE Cafe celebrates its 4th anniversary as it opens for another week...",
  content: [],
  pictures: [{ src: pic20161006 }],
  links: [],
},
{
  title:
    "February 18th, 2016 : Cambridge University Medical Students learn about the SMILE Cafe and its Growth",
  content: [
    `The pop-up SMILE Café operating 10am – 2pm, every Thursday, at the Fromus Centre, Saxmundham has recently launched a new conservatory extension, and Nick Corke, Rotary Club District Governor, was on hand to cut the ribbon and usher in the expanded seating, providing visitors with a view of the local flora and fauna while they enjoy their food and drink.`,
    <text>
      <em>
        “It’s good to see the successful Smile Café expanding and catering
        for even more customers. It’s a great example of PASTEL and the
        Rotary Club working together to provide a local community solution to
        a local community issue”{" "}
      </em>
      said Nick Corke.
    </text>,
    `On the same day, two medical students, Betty Xu and Karolos Dionelis - in their fifth year of medical studies at Cambridge University - opted to drop in and meet with SMILE Café customers and volunteers. They are both on a short stay, as part of their studies, with the Leiston Surgery - a local GP practice with a strong interest in medical education, being an accredited centre for training GP registrars, as well as undertaking a rolling training programme for medical students from Cambridge.`,
    `Both students have an interest in psychiatry and were keen to know how the SMILE café helps to reduce social isolation and provide a welcoming oasis for those seeking some relaxation, a chat and sharing emotional support with others. So, with coffee in hand, they circulated to find out more.`,
    <text>
      <em>
        “I think the SMILE Cafe has a really wonderful atmosphere – warm and
        vibrant. Doctors are very good at treating those who are really ill
        but, afterwards, the community can really help them ‘get back on
        their feet’. The SMILE Café is an excellent model and obviously very
        successful”{" "}
      </em>
      said Betty Xu.
    </text>,
    <text>
      <em>
        “Social isolation is a big issue and can leave some people with a
        lack of purpose. In the SMILE Café people can meet and chat, which
        can greatly improve both their physical and mental wellbeing. GP’s
        allocate appointments as 10 minute slots, but at the SMILE Café you
        can sit and chat for well over an hour…”{" "}
      </em>
      added Karolos Dionelis.
    </text>,
    <text>
      <em>
        “I live alone and look forward to my once-a week visit to the SMILE
        Café to meet others and share life’s experiences”{" "}
      </em>
      Janet, SMILE Café customer.
    </text>,
  ],
  pictures: [
    { src: pic201602181 },
    { src: pic201602182 },
    { src: pic201602183 },
    { src: pic201602184 },
    { src: pic201602185 },
    { src: pic201602186 },
  ],
  links: [],
},
{
  title:
    "April 28th, 2015 : The SMILE Cafe supports the Saxmundham Wellbeing Day",
  content: [
    <text>
      For further information about this event, please,{" "}
      <a href="http://www.fromuscentre.org.uk/content/news.html">
        click here.
      </a>
    </text>,
  ],
  pictures: [],
  links: [],
},
{
  title: "April 9th, 2015 : All change at the SMILE Cafe",
  content: [
    <text>
      The SMILE Cafe was set up on October 4th, 2012, by the{" "}
      <a href="http://www.pastel.org.uk/">
        PASTEL Community Interest Company{" "}
      </a>
      with support from
      <a href="www.saxmundhamrotary.org.uk">
        {" "}
        Rotary Club of Saxmundham & District.{" "}
      </a>
      It was sponsored by both organisations and became self-sustaining
      within the first year. The SMILE Cafe was managed by PASTEL for the
      first two and a half years, from October 2012 to April 2015, when the
      roles were reversed and the management was handed over to the Rotary
      Club, with PASTEL in support.
    </text>,
    `The SMILE Cafe will be closed in August and be relaunched with an refreshed menu in September`,
  ],
  pictures: [],
  links: [],
},
*/
