import React, { useState, useEffect } from "react";
import HomeModalContent from "../content/home-modal-content";

function HomeModal() {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setModal(true);
    }, 2000);
  }, []);
  return (
    <>
      <div
        id="myModal"
        className="modal"
        style={{ display: modal ? "block" : "none" }}
        onClick={() => setModal(false)}
      >
        <div className="modal-content">
          <span className="close" onClick={() => setModal(false)}>
            &times;
          </span>
          {HomeModalContent.map((item, key) => (
            <>
              <h2 key={key}>{item.title}</h2>
              {item.text.map((txt, key) => (
                <p className="pModal" key={key}>
                  {txt}
                </p>
              ))}
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default HomeModal;
