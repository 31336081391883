import SmileCafe from "../images/2022.11.09 - outside.jpg";
import Community from "../images/2022.11.09 - counter volunteers.jpg";
import Volunteer from "../images/2022.11.09 - customer and volunteer.jpg";
import Games from "../images/2022.11.09 - Ukelele.jpg";
import Customer from "../images/2022.11.09 - long shot customers.jpg";
import Money from "../images/money.jpg";
import Parking from "../images/2022.11.09 - carpark.jpg";

const HomePageContent = [
  {
    id: 1,
    content:
      "The SMILE Cafe is a weekly pop-up community cafe, running every Wednesday from 9.30am to 2.30pm, at the Fromus Centre, Saxmundham. IP17 1AL ",
    gif: <img src={SmileCafe} className="homePic" alt="intro pic" />,
  },
  {
    id: 2,
    content:
      "It's a community cafe for use by all residents in, and visitors to, the Saxmundham area. It provides an oasis for a relaxing break, with refreshments.",
    gif: <img src={Community} className="homePic" alt="intro pic" />,
  },
  {
    id: 3,
    content:
      "The SMILE Cafe is manned by volunteers who will serve you with a welcome cup of tea or coffee and a cheery smile. They will even sit and have a chat with you. We also serve hot and cold snacks and drinks, breakfasts and light lunches.",
    gif: <img src={Volunteer} className="homePic" alt="intro pic" />,
  },
  {
    id: 4,
    content:
      "We also provide background music, newspapers, playing cards, dominos and board games to make your time more enjoyable - and occasionally there's live entertainment!",
    gif: <img src={Games} className="homePic" alt="intro pic" />,
  },
  {
    id: 5,
    content:
      "The well-ventilated SMILE Cafe can seat up to 50 customers, at socially-distanced tables.",
    gif: <img src={Customer} className="homePic" alt="intro pic" />,
  },
  {
    id: 6,
    content: "We accept both cash and card payments.",
    gif: <img src={Money} className="homePic" alt="intro pic" />,
  },
  {
    id: 7,
    content:
      "There's plenty of onsite parking, within 5 minutes of the bus stop and within 10 minutes of Saxmundham rail station. And, by our car park cafe entrance, there are no less than 6 electric vehicle charging points!",
    gif: <img src={Parking} className="homePic" alt="intro pic" />,
  },
];

export default HomePageContent;
