import React from "react";
import GuruAward from "./GuruAward.js";

function Footer() {
  return (
    <div className="footerDiv">
      <GuruAward />
      <p className="footerP">
        © SMILE Community Cafe
        <br />
        <br />
        The SMILE Cafe
        <br />
        The Fromus Centre
        <br />
        Street Farm Road
        <br />
        Saxmundham IP17 1AL
        <br />
        <br />
        Email:
        <a href="mailto:dontricker4@gmail.com" className="emailAdd">
          dontricker4@gmail.com
        </a>
      </p>
    </div>
  );
}

export default Footer;
