import Volunteers from "../images/Volunteers.jpg";

const HomeModalContent = [
  {
    // title: (
    //   <text>
    //     Coronation Celebration Event!
    //     <br />
    //     (Wednesday May 3rd)
    //   </text>
    // ),
    text: [
      "If you live in or around Saxmundham, please check our Facebook page for the very latest SMILE Cafe information and news, including alerts for forthcoming live cafe entertainments!",
    ],
  },
];

export default HomeModalContent;
