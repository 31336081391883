import React from "react";
import { MenuContent, Extras } from "../content/menu-content";
import CustomerMenu from "../images/2022.11.09 - customer menu.jpg";
import Feeding from "../images/2022.11.09 - feeding.jpg";

export function Menu() {
  return (
    <>
      <h1>Menu</h1>
      <img src={CustomerMenu} className="menuPic" alt="Customer Menu" />
      <p className="detailsP">
        Pop-up cafe opening hours
        <br />
        Every Wednesday 9.30am to 2.30pm
        <br />
        (last cooked orders at 1.30pm)
      </p>
      <img src={Feeding} className="menuPic" alt="cooking" />
      <div className="menuContainer">
        {MenuContent.section.map((sec) => (
          <>
            <h3 className="h3Menu">{sec.title}</h3>
            <div className="menuTable">
              <table>
                <tbody>
                  {sec.items.map((rec) => (
                    <>
                      <tr>
                        <td>
                          <strong>{rec.item}</strong>
                        </td>
                        <td className="tdPrice">{rec.price}</td>
                      </tr>
                      <tr>
                        <td className="menuLabelTd">
                          <em>{rec.label}</em>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ))}
        {Extras.map((extra) => (
          <>
            <h3 className="h3Menu">{extra.title}</h3>
            {extra.content.map((item) => (
              <p className="pMobile">{item}</p>
            ))}
          </>
        ))}
      </div>
      <div classname="container"></div>
    </>
  );
}

export default Menu;
