import React from "react";
// import CustomerFeedback from "../content/customer-feedback-content";
import Vicar from "../images/2022.11.09 - vicar.jpg";

export function LatestNews() {
  return (
    <>
      <h1>Customer feedback</h1>
      <p className="pCF">
        If you have visited the SMILE Community Cafe and would like to comment
        on the cafe facilities and / or the menu offering and or the quality of
        the food and / or the value for money, please{" "}
        <a href="mailto:dontricker4@gmail.com " class="emailAdd">
          click here
        </a>
      </p>
      <p className="pCF">
        We always welcome positive feedback on TripAdvisor, please{" "}
        <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g680201-d12700981-Reviews-Smile_Cafe-Saxmundham_Suffolk_East_Anglia_England.html">
          click here
        </a>
      </p>
      <div
        className="picContainer"
        style={{ position: "relative", top: "150px", marginBottom: "20px" }}
      >
        <img src={Vicar} alt="vicar" className="feedbackPic" />
      </div>

      {/* {CustomerFeedback.map((article, index) => (
        <div className="articleContainer">
          <article className={index === 0 ? "article1" : "article"}>
            <h3>{article.title}</h3>
            {article.content.map((item) => (
              <p>{item}</p>
            ))}
            <div className="articleDivider"></div>
          </article>
        </div> */}
      {/* ))} */}
    </>
  );
}

export default LatestNews;
