import React from "react";
import HomeModal from "../components/HomeModal";
import HomePageContent from "../content/home-page-content";
import logo from "../images/smile-logo.png";

export function HomePage() {
  return (
    <>
      <h1 className="homeH1">Welcome to</h1>
      <div className="homeLogoDiv">
        <img src={logo} className="homeLogo" alt="logo"></img>
      </div>
      <HomeModal />
      {HomePageContent.map((item, key) => (
        <div
          className={
            "section" +
            (item.id <= 4 ? item.id : item.id % 4) +
            " " +
            "section" +
            (item.id <= 4 ? item.id : item.id % 4) +
            (item.id === 1 ? "a" : "")
          }
          key={key}
        >
          <div className="picText">
            {item.id % 2 === 0 && item.gif}
            <p className="pHome">{item.content}</p>
            {item.id % 2 !== 0 && item.gif}
          </div>
        </div>
      ))}
    </>
  );
}

export default HomePage;
