import React from "react";
import AboutContent from "../content/about-content";

export function About() {
  return (
    <>
      <h1>About Us</h1>
      {AboutContent.map((AboutContent, index) => (
        <div className="articleContainer" key={index}>
          <article className={index === 0 ? "article1" : "article"}>
            <h3>{AboutContent.title}</h3>
            {AboutContent.content.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
            <div className="articleDivider"></div>
          </article>
        </div>
      ))}
    </>
  );
}

export default About;
