import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import LatestNews from "./pages/LatestNews";
import CustomerFeedback from "./pages/CustomerFeedback";
import NotFoundPage from "./pages/NotFoundPage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Menu from "./pages/Menu";

export function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div id="page-body">
          <div className="App">
            <header className="App-header">
              <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/about" element={<About />} />
                <Route path="/latest_news" element={<LatestNews />} />
                <Route
                  path="/customer_feedback"
                  element={<CustomerFeedback />}
                />
              </Routes>
            </header>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}
export default App;
